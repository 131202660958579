import React from "react";
import { FaFacebookF } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Pdf from "../static/privacy-policy.pdf";
const Footer = () => {
  return (
    <footer>
      <div className="container padding-container">
        <div className="top">
          <div className="first">
            <div className="logo">
              <img src="/images/logo.png" alt="Logo" />
              <h1>
                Carnegie Tri-County <br /> Municipal Hospital
              </h1>
            </div>
            <div className="socials">
              <Link to="https://www.facebook.com/ctmhospital/">
                <FaFacebookF className="icon" />
              </Link>
            </div>
          </div>
          <div className="second">
            <Link to="/" className="link">
              Home
            </Link>
            <Link to="/about" className="link">
              About Us
            </Link>
            <Link to="/services" className="link">
              Our Services
            </Link>
            <Link to="/appointment" className="link">
              Appointment
            </Link>
            <Link to="/patient-info" className="link">
              Patient Information
            </Link>
            <Link
              to="https://recruiting.paylocity.com/recruiting/jobs/All/61a4644b-8505-4d28-bd52-31ce929f7f63/Cohesive-Staffing-Solutions?location=Carnegie&department=All%20Departments"
              className="link"
            >
              Employment
            </Link>
          </div>
          <div className="third">
            <p>
              Carnegie Tri-County Municipal Hospital <br />
              102 N Broadway <br />
              Carnegie, OK 73015 <br />
              580-654-1050
            </p>
          </div>
          <div className="fourth">
            <p>
              Carnegie Medical Clinic
              <br />
              101 W. Ash St. <br />
              Carnegie, OK 73015 <br />
              580-654-4444
            </p>
          </div>
        </div>
        <hr />
        <div className="bottom">
          <p>&copy; Copyright 2024, All Rights Reserved</p>
          <div className="last">
            <Link to={Pdf} target="_blank" className="link">
              Privacy Policy
            </Link>
            <Link to="https://cohesivehealthcare.net/terms" className="link">
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
