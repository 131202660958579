import React from "react";
import HomeNav from "../components/HomeNav";
import BlueBgBtn from "../components/BlueBgBtn";
import BorderBtn from "../components/BorderBtn";
import AboutUs from "../components/AboutUs";
import Testimonial from "../components/Testimonial";
import Appointment from "../components/Appointment";
import TouchWithUs from "../components/TouchWithUs";
import Footer from "../components/Footer";
import ServiceCard from "../components/ServiceCard";

const home = () => {
  return (
    <div className="main-container">
      <HomeNav />

      <section className="home-hero">
        <div className="overlay"></div>
        <div className="container padding-container">
          <div className="content">
            <h1>
              Your Trusted <span>Healthcare</span> Partner in Carnegie and
              Surrounding <span>Communities</span>
            </h1>
            <p>
              Providing quality, comprehensive, and compassionate medical care
              close to home. Welcome to Carnegie Tri-County Municipal Hospital,
              where we are committed to serving the healthcare needs of Carnegie
              and the surrounding communities. Conveniently located at 102 N
              Broadway, Carnegie, OK 73015, our facility is dedicated to
              providing exceptional medical services focusing on compassion and
              excellence.
            </p>
            <div className="hero-button">
              {/* <BlueBgBtn to="/appointment" text="Book an appointment" /> */}
            </div>
          </div>
          <div className="patterns">
            <img src="/images/patterns.png" alt="Patterns" />
          </div>
        </div>
      </section>

      <AboutUs />

      <section className="specialties">
        <div className="container padding-container">
          <div className="top">
            <img src="/images/stethoscope.png" alt="Stethoscope" />
            <h2>
              Our <span>Primary</span> Specialties
            </h2>
          </div>
          <ul>
            <li>24/7 Level 4 Emergency Room</li>
            <li>Outpatient Lab</li>
            <li>Strong Mind Program</li>
            <li>Radiology</li>
            <li>Physical and Occupational Therapy</li>
            <li>Inpatient Care</li>
          </ul>
          {/* 
          <div className="cards-container">
            <div className="speciality-card">
              <h1>24/7 Level 4 Emergency Room</h1>
              <p>
                Our emergency room is staffed 24/7 with highly skilled medical
                professionals, ensuring prompt and efficient care in the event
                of a medical emergency.
              </p>
            </div>
            <div className="speciality-card">
              <h1>Outpatient Lab</h1>
              <p>
                We provide comprehensive lab services on an outpatient basis,
                offering convenient access to diagnostic testing.
              </p>
            </div>
            <div className="speciality-card">
              <h1>Strong Mind Program</h1>
              <p>
                Our Strong Mind program focuses on the mental health needs of
                people aged 55+, offering group and individual counseling
                sessions to address issues such as depression, anxiety, and
                loneliness.
              </p>
            </div>
            <div className="speciality-card">
              <h1>Radiology</h1>
              <p>
                Our state-of-the-art radiology department offers a range of
                imaging services, including X-rays and CT scans to assist an
                accurate diagnosis and treatment planning.
              </p>
            </div>
            <div className="speciality-card">
              <h1>Physical and Occupational Therapy</h1>
              <p>
                Our dedicated therapy team offers personalized rehabilitation
                services to help patients recover from injuries, surgeries, or
                chronic conditions, and regain independence in their daily
                lives.
              </p>
            </div>
            <div className="speciality-card">
              <h1>Inpatient Care</h1>
              <p>
                We provide inpatient stays for patients with acute medical
                needs, ensuring comprehensive and compassionate care during
                their hospitalization.
              </p>
            </div>
          </div> */}
        </div>
      </section>

      <section className="services">
        <div className="container padding-container">
          <div className="first-content">
            <div className="top">
              <img src="/images/stethoscope.png" alt="Stethoscope" />
              <h2>
                Our <span>Services</span>
              </h2>
            </div>
            <p>
              At Carnegie Tri-County Municipal Hospital, we offer a wide range
              of medical services designed to meet the diverse needs of our
              patients. Our primary specialties include:
            </p>
            <BlueBgBtn to="/services" text="View more services" />
          </div>
          <div className="services-container">
            <ServiceCard
              img="/images/emergency.svg"
              title="Emergency"
              // content="Our team specializes in all types of emergency medicine, from broken bones to life-threatening injuries or illnesses. "
            />
            <ServiceCard
              img="/images/swingbed.svg"
              title="Swing Bed – Skilled Nursing Care Program"
              // content="After surgery or hospitalization, some patients require additional care and transfer into a nursing home or rehabilitation facility to help improve their physical functioning. "
            />
            <ServiceCard
              img="/images/therapy.svg"
              title="Respiratory Therapy"
              // content=" The Respiratory Therapy department helps manage the care of patients with cardiopulmonary issues."
            />
            <ServiceCard
              img="/images/test-tube.svg"
              title="Lab (Outpatient & Inpatient)"
              // content="Laboratory testing is performed 24 hours a day, seven days a week by certified laboratory personnel using the latest instrumentation."
            />
            <ServiceCard
              img="/images/psych.svg"
              title="Therapy Services"
              // content="Carnegie Tri-County Municipal Hospital provides comprehensive Physical Therapy, Occupational Therapy and Sports Medicine services to help our patients return to an independent lifestyle. "
            />
            <ServiceCard
              img="/images/radiology.svg"
              title="X-ray and CT (Outpatient & Inpatient)"
              // content="We are committed to providing premier-quality imaging with compassion and care. Whether a CT scan or a traditional X-ray, they are performed by skilled, registered
              // radiology technicians and read by experienced, board-certified radiologists. "
            />
          </div>
        </div>
      </section>

      {/* new policies section that was added */}
      <section className="policies">
        <div className="container padding-container">
          <div className="policies-row">
            <div className="cohesive-logo">
              <div className="logo-img">
                <img src="/images/cohesive-logo.svg" alt="logo" />
                <h2>Managed Hospital</h2>
              </div>
            </div>
            <div className="policy">
              <h3>Community Health Needs Assessment</h3>
              <p>
                Our Community Health Needs Assessment is designed to help
                identify and address the health needs of the communities we
                serve.
              </p>
              <p>To learn more, you can view our full assessment report.</p>
              <div className="link-btns">
                <a href="/documents/Carnegie-CHNA_2016.pdf">2016</a>
                <a
                  href="/documents/Carnegie-2019-Community-Health-Needs-Assessment.pdf"
                  target="_blank"
                >
                  2019
                </a>
              </div>
            </div>
            <div className="policy">
              <h3>Financial Assistance Policy</h3>
              <p>
                No one will be denied access to services due to inability to
                pay. We utilize a discounted/sliding fee scale based on family
                size and income.
              </p>
              <p>
                To learn more about financial assistance or to print a financial
                assistance application you can click below.
              </p>
              <div className="link-btns">
                <a
                  href="/documents/2020-Financial-Assistance-Policy.CARNEGIE.tdc_.pdf"
                  target="_blank"
                >
                  FA Policy
                </a>
                <a
                  href="https://www.carnegiehospital.com/documents/2020-APPLICATION.Carnegie.-Patient-Assistance-Application.pdf"
                  target="_blank"
                >
                  FA Application
                </a>
              </div>
            </div>
            {/* <div className="policy">
              <h3>Non-Discrimination Policy</h3>
              <p>
                We accept all patients regardless of race, color, national origin, age, disability, sex, sexual orientation, gender identity, religion, or ability to pay.
              </p>
              <p>
                To learn more, you can view our full non-discrimination policy.
              </p>
              <a href="" target="_blank">Non-Discrimination Policy</a>
            </div> */}
          </div>
        </div>
      </section>

      {/* <Testimonial /> */}
      {/* <Appointment /> */}
      <TouchWithUs />
      <Footer />
    </div>
  );
};

export default home;
