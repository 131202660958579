import React from "react";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import AboutUs from "../components/AboutUs";
import TeamCard from "../components/TeamCard";
import Testimonial from "../components/Testimonial";
import BlogCard from "../components/BlogCard";
import Appointment from "../components/Appointment";
import Footer from "../components/Footer";
import "../static/about/about.css";
import BlueBgBtn from "../components/BlueBgBtn";

export const patientInfo = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero
        className="general-hero patient-info-hero"
        text="Patient Information"
      />
      <section className="choose-us">
        <div className="container padding-container">
          <div className="text-content">
            <div className="top">
              <img src="/images/stethoscope.png" alt="Stethoscope" />
              <h2>
                <span>FA</span> Policy
              </h2>
            </div>
            <p>
              No one will be denied access to services due to inability to pay.
              We utilize a discounted/sliding fee scale based on family size and
              income.
            </p>
            <p>
              Click the link below to learn more about Financial Assistance
              Policy.
            </p>
            <p>
              <BlueBgBtn
                to="https://www.carnegiehospital.com/documents/2020-Financial-Assistance-Policy.CARNEGIE.tdc_.pdf"
                text="FA Policy"
              />
            </p>
          </div>

          <div className="img-container">
            <img src="/images/services-hero.jpg" alt="Doctors Smiling" />
            <div className="blue-container"></div>
          </div>
        </div>
      </section>

      <section className=" choose-us price-transparency">
        <div className="container padding-container">
          <div className="img-container">
            <img src="/images/lab-service.jpg" alt="Doctors Smiling" />
            <div className="blue-container"></div>
          </div>
          <div className="text-content">
            <div className="top">
              <img src="/images/stethoscope.png" alt="Stethoscope" />
              <h2>
                <span>FA</span> Application
              </h2>
            </div>
            <p>
              To print a financial assistance application you can click below.
            </p>
            <p>
              <BlueBgBtn
                to="https://www.carnegiehospital.com/documents/2020-APPLICATION.Carnegie.-Patient-Assistance-Application.pdf"
                text="FA Application"
              />
            </p>
          </div>
        </div>
      </section>
      <section className="choose-us">
        <div className="container padding-container">
          <div className="text-content">
            <div className="top">
              <img src="/images/stethoscope.png" alt="Stethoscope" />
              <h2>
                <span>Price</span> Transparency
              </h2>
            </div>
            <p>
              We strive to be transparent with pricing for our services, so
              we’ve provided multiple pricing tools to help you better
              anticipate medical costs and understand charges on your bill.
            </p>
            <p>Click the link below to learn more about Price Transparency.</p>
            <p>
              <BlueBgBtn
                to="https://apps.para-hcfs.com/PTT/FinalLinks/CarnegieTriCounty_V2.aspx"
                text="Price Transparency"
              />
            </p>
          </div>

          <div className="img-container">
            <img src="/images/appointment-img.jpg" alt="Doctors Smiling" />
            <div className="blue-container"></div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default patientInfo;
