import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./html/home";
import About from "./html/about";
import Contact from "./html/contact";
import Services from "./html/services";
import Lab from "./html/lab";
import XRay from "./html/x-ray";
import Appointment from "./html/appointment";
import Swingbed from "./html/swingbed";
import Emergency from "./html/emergency";
import Respiratory from "./html/respiratory";
import Psychiatric from "./html/psychiatric";
import Nursing from "./html/nursing";
import Ultrasound from "./html/ultrasound";
import WoundCare from "./html/wound-care";
import CaseManagement from "./html/case-management";
import Rehabilitation from "./html/rehabilitation";
import GoToTop from "./components/GoToTop";
import Clinic from "./html/clinic";
import Employment from "./html/employment";
import PatientInfo from "./html/patient-info";
function App() {
  return (
    <BrowserRouter>
      <GoToTop />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/lab" element={<Lab />} />
          <Route path="/services/x-ray" element={<XRay />} />
          <Route path="/services/swingbed" element={<Swingbed />} />
          <Route path="/services/emergency" element={<Emergency />} />
          <Route path="/services/respiratory" element={<Respiratory />} />
          <Route path="/services/psychiatric" element={<Psychiatric />} />
          <Route path="/services/nursing" element={<Nursing />} />
          <Route path="/services/rehabilitation" element={<Rehabilitation />} />
          <Route path="/services/ultrasound" element={<Ultrasound />} />
          <Route path="/services/wound-care" element={<WoundCare />} />
          <Route path="/clinic" element={<Clinic />} />

          <Route path="/patient-info" element={<PatientInfo />} />

          <Route
            path="/services/case-management"
            element={<CaseManagement />}
          />
          <Route
            path="/services/therapy-services"
            element={<Rehabilitation />}
          />

          <Route path="/appointment" element={<Appointment />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
